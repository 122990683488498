@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Avenir;
  src: url(./assets/fonts/Avenir.otf);
}
/* 301f14 */
/* 9b6a13 */

html{
}

body {
  margin: 0;
  font-family: 'Avenir';
}

.en{
  direction: ltr;
}

#root{
  overflow-x: hidden !important;
}

h1,h2,h3,h4,h5,h6,div,p,span,input,form,label,a,section{
  font-family: 'Avenir' !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 25px;
  bottom: 25px;
  width: 50px;
  height: 50px;
  padding-right: 17px;
  padding-top: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #301f14;
  color: #fff;
  z-index: 99;
}

.whatsapp-icon{
  position: fixed;
  bottom: 25px;
  left: 25px;
  background-color: #0da019 !important;
  color: #ffffff !important;   
  border-radius: 100% !important;
  width: 50px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
  z-index: 3;
}
.whatsapp-icon::before {
content: "";
position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
border-radius: 50%;
border: 2px solid #25d366;
opacity: 0.75;
animation-name: pulse-border;
animation-duration: 1.5s;
animation-timing-function: ease-out;
animation-iteration-count: infinite;
}

@keyframes pulse-border {
0% {
  padding: 25px;
  opacity: 0.75;
}
75% {
  padding: 50px;
  opacity: 0;
}
100% {
  opacity: 0;
}
}
.whatsapp-icon:hover{
  background-color: #15861e !important;
}
.whatsapp-icon .icon-2{
  font-size: 30px;
 position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.slider-caption{
  position: absolute;
  
  transition: 0.5s all ease-in-out !important;
}

.features .features-icon{
  animation: rotation 2s linear forwards infinite;
}

@keyframes rotation {
  from{
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}

.about-cover{
  background-image: url('./assets/images/decoration2@2x.png');
  left: 0;
  opacity: 0.7;
  transform: rotateZ(180deg);
}
.about-cover-img{
  border-radius: 20px;
}
.about-cover-logo{
  background-image: url('./assets/images/white-logo2@2x.png');
  top: 50%;
  left: 50%;
  transform: translate(-50%,-10%); 
  background-repeat: no-repeat;
  opacity: 0.9;
}
.about-cover-container::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  /* display: block; */
  /* border: 4px solid #c98510; */
  background-color: #c98510;
  top: 10px;
  right: 20px;
  border-radius: 20px;
  z-index: -1;

}

.service-card{
  overflow: hidden;
}

.service-card::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  background-color: #301f14;
  z-index: -1;
  transition: all 0.4s ease-out !important;
}

.service-card:hover::before{
  height: 100%;
}

.services-card-cover{
  background-image: url('./assets/images/decoration2@2x.png');
  left: 0;
  transform: rotateZ(180deg);
}

.other-services-card{
  transition: all 0.2s ease-in-out !important;
  border-bottom: 5px solid #fff;
}

.other-services-card:hover{
  top: -10px;
  border-bottom-color:#c98510;
}

#solutions-section li{
color: #fff;
}
#solutions-section li:hover{
  background-color: rgba(255,255,255,0.1);
  }
#solutions-section li:not(:last-child){
  border-bottom: 1px solid rgba(255,255,255,0.3);
}

.bannar-cover{
  background-image: url('./assets/images/decoration2@2x.png');
  right: 0;
  opacity: 0.8;
} 

/* .php-email-form label{
  color: #fff !important;
}

.php-email-form input, .php-email-form textarea{
  border-color: #fff !important;
}

.php-email-form textarea:focus{
  border-color: #fff !important;
} */

.php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}
.php-email-form .error-message {
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.php-email-form .error-message br + br {
  margin-top: 25px;
}
.php-email-form .sent-message {
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.footer-cover{
  background-image: url('./assets/images/bg1@2x.png');
  right: 0;
  opacity: 0.15;
}

@media(min-width: 1280px){
  .en .footer-contain{
    text-align: left;
  }
}